import React from 'react'
import { Parser } from 'html-to-react'
import { css } from '@emotion/core'
import fecha from 'fecha'
import { ScrollAnimated } from './ScrollAnimated'

const parser = new Parser()

const schoolNameStyles = css`
  @media screen and (max-width: 475px) {
    display: block;
    .time {
      position: relative;
      top: -1rem;
    }
  }
  display: flex;
  justify-content: space-between;
  align-items: baseline;

  .time {
    font-size: 0.9rem;
  }

  .time > span {
    white-space: nowrap;
  }
`

const dateFormat = 'MMM YYYY'

const Degree = ({ degree }) => (
  <ScrollAnimated>
    <div
      css={css`
        page-break-inside: avoid;
      `}
    >
      <div css={schoolNameStyles}>
        {parser.parse(degree.school.html)}

        <div className="time">
          <span>{fecha.format(new Date(degree.from), dateFormat)}</span> -{' '}
          <span>{fecha.format(new Date(degree.until), dateFormat)}</span>
        </div>
      </div>
      {parser.parse(degree.degree.html)}
    </div>
  </ScrollAnimated>
)

export default Degree
