import { css, Global } from '@emotion/core'
import React from 'react'
import Helmet from 'react-helmet'

const globalStyle = css`
  html,
  body,
  div,
  span,
  applet,
  object,
  iframe,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  a,
  abbr,
  acronym,
  address,
  big,
  cite,
  code,
  del,
  dfn,
  em,
  img,
  ins,
  kbd,
  q,
  s,
  samp,
  small,
  strike,
  strong,
  sub,
  sup,
  tt,
  var,
  b,
  u,
  i,
  center,
  dl,
  dt,
  dd,
  ol,
  ul,
  li,
  fieldset,
  form,
  label,
  legend,
  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td,
  article,
  aside,
  canvas,
  details,
  embed,
  figure,
  figcaption,
  footer,
  header,
  hgroup,
  menu,
  nav,
  output,
  ruby,
  section,
  summary,
  time,
  mark,
  audio,
  video {
    -webkit-print-color-adjust: exact;
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;
  }
  /* HTML5 display-role reset for older browsers */
  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  menu,
  nav,
  section {
    display: block;
  }
  html {
    font-size: 20px;

    @media screen and (max-width: 916px) and (min-width: 476px) {
      font-size: 15px;
    }

    @media screen and (max-width: 475px) {
      font-size: 14px;
    }

    @media print {
      font-size: 12px;
    }
  }
  body {
    line-height: 1.5;
  }
  ul {
    margin-left: 1rem;
  }
  blockquote,
  q {
    quotes: none;
  }
  blockquote:before,
  blockquote:after,
  q:before,
  q:after {
    content: '';
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
  html,
  button,
  input,
  textarea {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: 'Anonymous Pro', monospace;
  }
  a {
    color: black;
  }
`

function createJsonLd({ name, jobTitle, homepage, picture, gitlab, linkedin, nationality, birthDate }) {
  const personData = {
    '@context': 'https://schema.org',
    '@type': 'Person',
    name,
    jobTitle,
    nationality,
    birthDate,
    image: picture,
    sameAs: [],
  }

  if (homepage) {
    personData.url = homepage
  }

  if (gitlab) {
    personData.sameAs.push(gitlab)
  }

  if (linkedin) {
    personData.sameAs.push(linkedin)
  }

  return JSON.stringify(personData)
}

const Layout = ({ person, children }) => (
  <>
    <Global styles={globalStyle} />
    <Helmet>
      <html lang="en" />
      <link
        href="https://fonts.googleapis.com/css?family=Anonymous+Pro:400,400i,700,700i&display=swap"
        rel="stylesheet"
      />
      <title>{person.name}</title>
      <meta name="description" content={`Curriculum Vitae of ${person.name}, ${person.jobTitle}`} />
      <script type="application/ld+json">{createJsonLd(person)}</script>

      {/* image meta tags for social media*/}
      <meta property="twitter:image" content={person.picture} />
      <meta property="og:image" content={person.picture} />

    </Helmet>
    {children}
  </>
)

export default Layout
