import { css } from '@emotion/core'
import { graphql } from 'gatsby'
import { Parser } from 'html-to-react'
import React from 'react'
import Layout from '../components/Layout'
import WorkExperience from '../components/WorkExperience'
import Education from '../components/Education'
import Skills from '../components/Skills'
import Contact from '../components/Contact'
import theme from '../../theme.json'

const container = css`
  margin: 0 auto;
  padding: 4rem 2rem 8rem 2rem;

  @media print {
    margin: 0;
    padding: 2rem 2rem 8rem 2rem;
    max-width: unset;
  }

  max-width: 40rem;
  color: ${theme.primaryColor};

  h1 {
    font-size: 1.5rem;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 1rem;
  }

  h2 {
    margin-top: 4rem;
    font-size: 1.25rem;
    text-transform: uppercase;
  }

  h3 {
    margin-top: 2rem;
    margin-bottom: 1rem;
    font-size: 1.1rem;
    font-weight: 600;
    letter-spacing: 1.2px;
  }

  a {
    text-decoration: none;
  }
  a:hover {
    text-decoration: underline;
  }

  .grey {
    color: ${theme.grey};
  }

  @media print {
    .hide-on-print {
      display: none;
    }
  }

  .print-only {
    display: none;
  }
  @media print {
    .print-only {
      display: unset;
    }
  }
`

const introStyles = css`
  display: flex;

  .img-container {
    width: 3cm;
    margin-left: 1.5cm;
    text-align: right;
    flex: 0 0 auto;
  }

  img {
    width: 100%;
  }
`

const htmlToReactParser = new Parser()

export default props => {
  const { data } = props
  const content = data.prismicHomepage.data
  const name = content.name.text
  const picture = data.contact.primary.picture.url

  const person = {
    name,
    picture,
    birthDate: content.birth_date,
    nationality: content.nationality.text,
    jobTitle: content.job_title.text,
    homepage: data.contact.primary.web.url,
    gitlab: data.contact.primary.gitlab.url,
    github: data.contact.primary.github.url,
    linkedin: data.contact.primary.linkedin.url,
  }
  const description = content.description.html

  return (
    <Layout person={person}>
      <div css={container}>
        <div css={introStyles}>
          <div>
            <h1>{name}</h1>
            {htmlToReactParser.parse(description)}
          </div>
          <div className="img-container print-only">
            <img src={picture} alt={data.contact.primary.picture.alt} />
          </div>
        </div>

        <Contact contact={data.contact} />
        <WorkExperience experience={data.workExperience} />
        <Education education={data.education} />
        <Skills skills={data.skills} />
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    contact: prismicHomepageBodyContact {
      primary {
        picture {
          url
          alt
        }
        web {
          url
          target
        }
        mail {
          url
          target
        }
        phone {
          url
          target
        }
        linkedin {
          url
          target
        }
        gitlab {
          url
          target
        }
        github {
          url
          target
        }
      }
    }
    education: prismicHomepageBodyEducation {
      primary {
        education_title {
          html
        }
      }
      items {
        school {
          html
          text
        }
        degree {
          html
        }
        from
        until
      }
    }
    workExperience: prismicHomepageBodyWorkExperience {
      slice_type
      primary {
        work_experience_title {
          html
        }
      }
      items {
        employer {
          html
          text
        }
        job_title {
          text
        }
        responsibilities {
          html
          text
        }
        link_to_company {
          url
          link_type
          target
        }
        from
        until
        current_job
      }
    }
    skills: prismicHomepageBodySkills {
      primary {
        title {
          html
          text
        }
      }
      items {
        skill {
          html
          text
        }
        level
      }
    }
    prismicHomepage {
      data {
        name {
          text
        }
        job_title {
          text
        }
        birth_date
        nationality {
          text
        }
        description {
          html
        }
      }
    }
  }
`
