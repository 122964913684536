import React from 'react'
import { Parser } from 'html-to-react'
import StarRatingComponent from 'react-star-rating-component'
import { css } from '@emotion/core'
import theme from '../../theme.json'

const parser = new Parser()

const skillStyles = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;

  .rating {
    flex-shrink: 0;
  }

  .skill-description {
    padding-right: 2rem;
  }

  i {
    font-size: 1.15rem;
  }

  @media screen and (max-width: 475px) {
    i {
      font-size: 1.75rem;
    }
  }
`

const Skill = ({ skill: { skill, level } }) => (
  <div css={skillStyles}>
    <div className="skill-description">{parser.parse(skill.html)}</div>
    <StarRatingComponent
      className="rating"
      name={skill.text.replace(/[^a-zA-Z0-9\\s]/g, '')}
      editing={false}
      starCount={5}
      starColor={theme.primaryColor}
      emptyStarColor={theme.greyLighter}
      value={level / 2}
    />
  </div>
)

export default Skill
