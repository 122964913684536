import React from 'react'
import * as ScrollAnimation from 'react-animate-on-scroll'
import { css } from '@emotion/core'

const animatedCss = css`
  &.animated {
    animation-duration: 0.5s;
    animation-fill-mode: both;
  }

  @media print {
    &.animated {
      animation-duration: 0 !important;
      opacity: 1 !important;
    }
  }

  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translate3d(0, 1rem, 0);
    }

    to {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }

  &.fadeInUp {
    animation-name: fadeInUp;
  }

  @media print, (prefers-reduced-motion: reduce) {
    &.fadeInUp {
      animation-name: none !important;
    }
  }
`

export const ScrollAnimated = ({ children }) => (
  <ScrollAnimation css={animatedCss} animateIn="fadeInUp">
    {children}
  </ScrollAnimation>
)
