import React from 'react'
import { Parser } from 'html-to-react'
import Job from './Job'
import { css } from '@emotion/core'

const htmlToReactParser = new Parser()

const WorkExperience = ({ experience: { primary, items } }) => (
  <div  css={css`
    @media print {
      page-break-after: always;
    }
  `}>
    {htmlToReactParser.parse(primary.work_experience_title.html)}
    {items.map(job => (
      <Job key={job.employer.text} job={job} />
    ))}
  </div>
)

export default WorkExperience
