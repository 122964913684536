import React from 'react'
import { Parser } from 'html-to-react'
import { css } from '@emotion/core'
import fecha from 'fecha'
import { ScrollAnimated } from './ScrollAnimated'

const parser = new Parser()

const titleStyles = css`
  display: flex;
  justify-content: space-between;
  align-items: baseline;

  .time {
    font-size: 0.9rem;
  }

  @media screen and (max-width: 475px) {
    display: block;
    .time {
      position: relative;
      top: -1rem;
    }
  }
`
const jobTitleStyles = css`
  font-style: italic;
  font-weight: bold;
  margin-bottom: 1rem;
`

const dateFormat = 'MMM YYYY'

const Job = ({ job }) => (
  <ScrollAnimated>
    <div
      css={css`
        page-break-inside: avoid;
      `}
    >
      <div css={titleStyles}>
        {job.link_to_company ? (
          <a href={job.link_to_company.url} target={job.link_to_company.target} rel="noopener">
            {parser.parse(job.employer.html)}
          </a>
        ) : (
          parser.parse(job.employer.html)
        )}

        <span className="time">
          {fecha.format(new Date(job.from), dateFormat)} -{' '}
          {job.current_job === 'No' ? fecha.format(new Date(job.until), dateFormat) : 'now'}
        </span>
      </div>
      <p css={jobTitleStyles}>{job.job_title.text}</p>
      <div>{parser.parse(job.responsibilities.html)}</div>
    </div>
  </ScrollAnimated>
)

export default Job
