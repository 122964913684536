import React from 'react'
import { css } from '@emotion/core'
import { Mail, Link, Linkedin, Gitlab, GitHub } from 'react-feather'
import theme from '../../theme'

const contactStyles = css`
  margin-top: 2rem;

  display: grid;

  grid-template-columns: repeat(4, 1fr);

  @media screen and (max-width: 475px) {
    grid-template-columns: repeat(1, 1fr);
  }

  @media print {
    grid-template-columns: repeat(6, 1fr);
  }

  a {
    color: ${theme.primaryColor};
    text-decoration: underline;
    
    @media screen and (max-width: 475px) {
        line-height: 2.5;
        padding: 1rem 0;
    }
  }

  svg + span {
    position: relative;
    top: -4px;
    margin-left: 0.5rem;
  }
`

const iconSize = '1rem'

const ContactLink = ({ text, url, target, icon: Icon, className }) => (
  <div className={className}>
    <a href={url} target={target} rel="noopener">
      <Icon size={iconSize} />
      <span>{text}</span>
    </a>
  </div>
)

const Contact = ({ contact: { primary: contact } }) => (
  <div css={contactStyles}>
    <ContactLink url={contact.web.url} target={contact.web.target} text="Website" icon={Link} className="print-only" />
    <ContactLink url={contact.mail.url} target={contact.mail.target} text="Email" icon={Mail} />
    <ContactLink url={contact.linkedin.url} target={contact.linkedin.target} text="LinkedIn" icon={Linkedin} />
    <ContactLink url={contact.gitlab.url} target={contact.gitlab.target} text="GitLab" icon={Gitlab} />
    <ContactLink url={contact.github.url} target={contact.github.target} text="GitHub" icon={GitHub} />
  </div>
)

export default Contact
