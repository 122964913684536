import React from 'react'
import { Parser } from 'html-to-react'
import Degree from './Degree'
import { css } from '@emotion/core'

const parser = new Parser()

const Education = ({ education: { primary, items } }) => (
  <div
    css={css`
      page-break-inside: avoid;
    `}
  >
    {parser.parse(primary.education_title.html)}
    {items.map(degree => (
      <Degree key={degree.school.text} degree={degree} />
    ))}
  </div>
)

export default Education
