import React from 'react'
import { css } from '@emotion/core'
import Skill from './Skill'

const Skills = ({ skills: { primary, items } }) => (
  <div
    css={css`
      page-break-inside: avoid;
    `}
  >
    <h2 style={{ marginBottom: '2rem' }}>{primary.title.text}</h2>
    {items.map(skill => (
      <Skill key={skill.skill.text} skill={skill} />
    ))}
  </div>
)

export default Skills
